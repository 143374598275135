import { Controller } from "@hotwired/stimulus";
import {
  GridOptions,
  RowNode,
  IAggFuncParams,
  SortDirection,
  createGrid,
  ColumnPinnedType,
  RowGroupingDisplayType,
  GetRowIdParams,
  ToolPanelDef,
  GetContextMenuItemsParams,
  MenuItemDef,
  GridApi,
  ValueGetterParams,
  ColDef,
  RowClassParams,
} from "ag-grid-enterprise";
import {
  AccountCellRenderer,
  FlattenedAccountCellRenderer,
  NumberCellRenderer,
  tagHeaderTemplate,
  consolidatedHeaderTemplate,
  HeaderGroupRenderer,
} from "../helpers/reports/renderers";
import { processSavedReport, saveReportTemplate, updateSavedReportTemplate } from "../helpers/reports/saved_reports";
import {
  defaultExportParams,
  getClassesForAccounts,
  getClassesForCells,
  excelStyles,
  headerRows,
} from "../helpers/reports/excel_options";
import { dataTypeDefinitions, formatDateForDisplay } from "../helpers/reports/formatters";
import { aggColumnValues } from "../helpers/reports";
import { LoadingOverlay, NoRowsOverlay } from "../helpers/reports/overlays";
import { isGroupHeader } from "../helpers/reports/node_helpers";
import {
  calculatePercentChangeTotal,
  validateDateSelected,
  setDateAndAlertDisplay,
  requestReportData,
  processReportDataResponse,
  validateEntitySet,
  hasOption,
  getOptionState,
  numberValueGetter,
  updateBreadcrumb,
  shouldRefreshGrouping,
} from "../helpers/reports/utils";
import { RowData, IGridOptionsValue, ServerProvidedColDef, ReportOption, ReportType } from "../types";
import {
  getCheckboxValues,
  setOptionsCheckboxValue,
  setCheckboxValues,
  setSelectValues,
  getSelectValues,
} from "../helpers/reports/index";
import { loadDateInputs, prepareDateRangeOptions } from "../helpers/relative_dates";

// Connects to data-controller="reporting"
export default class extends Controller {
  totalCols = new Set() as Set<string>;
  consolidatedCols = new Set() as Set<string>;
  options = {};

  static targets = [
    "form",
    "grid",
    "dateRange",
    "startDate",
    "endDate",
    "ledgers",
    "compareable",
    "option",
    "mergeEntitiesToggle",
    "ledgers",
    "compareToLastPeriod",
    "colTag",
    "rowTag",
    "rowTagsButton",
    "accountCode",
    "intervalSelect",
    "consolidationFormatSelect",
    "ledgerErrors",
    "dateErrors",
    "exportButton",
    "updateReportButton",
    "saveReportButton",
    "dateDisplay",
    "alertDisplay",
    "saveReportName",
    "saveReportType",
  ];

  static values = {
    gridOptions: Object,
    reportType: String,
    enableGroupSubTotals: {
      type: Boolean,
      default: true,
    },
    reportTemplateId: String,
    // We asynchronously load account codes and tags, so we need to know when we are done loading them
    asyncInputsLoaded: {
      type: Boolean,
      default: false,
    },
  };

  // Targets
  declare gridTarget: HTMLDivElement;
  declare formTarget: HTMLFormElement;
  declare ledgersTarget: HTMLSelectElement;
  declare ledgerErrorsTarget: HTMLSpanElement;
  declare dateErrorsTarget: HTMLSpanElement;
  declare optionTargets: HTMLInputElement[];
  declare hasMergeEntitiesToggleTarget: boolean;
  declare mergeEntitiesToggleTarget: HTMLInputElement;

  declare dateRangeTarget: HTMLSelectElement;
  declare hasDateRangeTarget: boolean;
  declare startDateTarget: HTMLInputElement;
  declare hasStartDateTarget: boolean;
  declare endDateTarget: HTMLInputElement;
  declare hasEndDateTarget: boolean;
  declare compareableTarget: HTMLInputElement;
  declare hasCompareableTarget: boolean;
  declare compareToLastPeriodTarget: HTMLInputElement;
  declare hasCompareToLastPeriodTarget: boolean;
  declare intervalSelectTarget: HTMLSelectElement;
  declare hasIntervalSelectTarget: boolean;
  declare consolidationFormatSelectTarget: HTMLSelectElement;
  declare hasConsolidationFormatSelectTarget: boolean;

  declare colTagTargets: HTMLInputElement[];
  declare rowTagTargets: HTMLInputElement[];
  declare rowTagsButtonTarget: HTMLButtonElement;
  declare hasRowTagsButtonTarget: boolean;
  declare accountCodeTargets: HTMLInputElement[];

  declare dateDisplayTarget: HTMLDivElement;
  declare alertDisplayTarget: HTMLSpanElement;

  declare exportButtonTarget: HTMLButtonElement;
  declare hasExportButtonTarget: boolean;

  declare updateReportButtonTarget: HTMLButtonElement;
  declare saveReportButtonTarget: HTMLButtonElement;
  declare hasUpdateReportButtonTarget: boolean;
  declare hasSaveReportButtonTarget: boolean;

  declare saveReportNameTarget: HTMLInputElement;
  declare saveReportTypeTarget: HTMLInputElement;

  // Values
  declare gridOptionsValue: IGridOptionsValue;
  declare reportTypeValue: string;
  declare hasReportTypeValue: boolean;
  declare enableGroupSubTotalsValue: boolean;
  declare reportTemplateIdValue: string;

  // Local variables
  declare gridApi: GridApi;
  declare gridOptions: GridOptions;

  declare asyncInputsLoadedValue: boolean;

  connect() {
    this.gridTarget.classList.remove("loaded");

    // Ensure the export button is disabled until the grid is loaded
    if (this.hasExportButtonTarget) {
      this.exportButtonTarget.disabled = true;
    }
    if (this.hasSaveReportButtonTarget) {
      this.saveReportButtonTarget.disabled = true;
    }

    this.gridOptions = {
      columnDefs: null,
      headerHeight: this.consolidatedReport() ? 100 : 40,
      aggFuncs: { aggPercentChangeTotal: this.aggPercentChangeTotal },
      defaultColDef: {
        // allow every column to be aggregated
        enableValue: true,
        // allow every column to be grouped
        enableRowGroup: true,
        suppressHeaderFilterButton: true,
      },
      getContextMenuItems: (_params: GetContextMenuItemsParams) => {
        // Removing the export option since we have the api call to export
        const result: (string | MenuItemDef)[] = [
          "autoSizeAll",
          "separator",
          "expandAll",
          "contractAll",
          "separator",
          "copy",
          "copyWithHeaders",
          "copyWithGroupHeaders",
        ];

        return result;
      },
      excelStyles: excelStyles,
      autoGroupColumnDef: this.accountGroupColumnDefinition,
      groupDisplayType: "singleColumn" as RowGroupingDisplayType,
      suppressMoveWhenRowDragging: true,
      rowData: null,
      // enable Tree Data mode
      treeData: true,
      // expand all groups by default
      groupDefaultExpanded: -1,
      getDataPath: (data: RowData) => {
        return data.accountBreadCrumbs;
      },
      getRowId: (params: GetRowIdParams) => {
        return params.data.id.toString();
      },
      statusBar: {
        statusPanels: [
          { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
          { statusPanel: "agTotalRowCountComponent", align: "center" },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
            },
          } as ToolPanelDef,
        ],
      },
      allowContextMenuWithControlKey: true,
      // adds subtotals
      groupTotalRow: "bottom",
      grandTotalRow: this.reportTypeValue === "trial_balance" ? "bottom" : null,
      dataTypeDefinitions: {
        ...dataTypeDefinitions,
      },
      getRowStyle: (params: RowClassParams) => {
        let rowStyles = {};
        const node = params.node as RowNode;
        const level = node.level;

        if (node.hasChildren() || node.rowPinned) {
          rowStyles = {
            "font-weight": 700,
          };
        }

        if (node.hasChildren() && node.footer) {
          const isRootLevel = level === -1;

          if (isRootLevel) {
            return Object.assign(rowStyles, { "background-color": "#CCE7EA", "border-top": "1px solid black" });
          } else if (level === 0) {
            return Object.assign(rowStyles, {
              "background-color": "#e5e7eb",
              "border-top": "1px solid black",
              "border-bottom": "1px solid black",
            });
          } else {
            return Object.assign(rowStyles, { "border-top": "1px solid black", "border-bottom": "1px solid black" });
          }
        } else if (node.data?.metadata?.generated_total_row) {
          return Object.assign(rowStyles, {
            "background-color": "#e5e7eb",
            "border-top": "1px solid black",
            "border-bottom": "1px solid black",
          });
        }
        return rowStyles;
      },
      noRowsOverlayComponent: NoRowsOverlay,
      loadingOverlayComponent: LoadingOverlay,
      components: {
        accountCellRenderer: AccountCellRenderer,
        flattenedAccountCellRenderer: FlattenedAccountCellRenderer,
      },
      onRowDataUpdated: () => {
        this.gridTarget.classList.add("loaded");
      },
      cellSelection: true,
    };

    // update additional custom options from gridOptions
    for (const option in this.gridOptionsValue) {
      // eslint-disable-next-line no-prototype-builtins
      if (!this.gridOptions.hasOwnProperty(option)) {
        continue;
      }

      this.gridOptions[option] = this.gridOptionsValue[option];
    }

    if (this.reportTemplateIdValue) {
      processSavedReport(this, this.reportTemplateIdValue);
    } else if (this.gridOptionsValue.autoLoadReport) {
      this.createGrid();
      this.loadGridData(new Event("autoload"));
    } else {
      // Clear the report options from session storage to avoid loading old options
      sessionStorage.removeItem(this.sessionStorageKey("report_options"));
      this.createGrid();
    }
  }

  createGrid() {
    this.gridApi = createGrid(this.gridTarget, this.gridOptions);
    this.gridApi.showNoRowsOverlay();
  }

  // We need to set the grid state before the grid is created in order for it to work.
  // Currently there is no way to update the state after the grid had been initialized.
  // State is set using initialState: https://github.com/ag-grid/ag-grid/issues/7445
  loadAgGridState(agGridState) {
    if (agGridState) {
      this.gridOptions.initialState = agGridState;

      // Rely on the state to expand groups by default
      this.gridOptions.groupDefaultExpanded = 0;
    }
  }

  disconnect() {
    if (this.gridApi) {
      this.gridApi.destroy();
    }
  }

  async loadGridData(event: Event) {
    this.gridApi.setGridOption("loading", true);
    this.gridTarget.classList.remove("loaded");

    this.addAutoLoadParamToUrl();

    if (!this.optionTargets.includes(event.currentTarget as HTMLInputElement)) {
      event.preventDefault();
    }

    this.totalCols.clear();

    const sessionReportOptions = JSON.parse(sessionStorage.getItem(this.sessionStorageKey("report_options")));

    if (event.type == "autoload" && sessionReportOptions) {
      this.loadValuesToInputs(sessionReportOptions);
    }

    if (!this.validateEntitySetIfConsolidatedReport()) {
      return;
    }

    if (!validateDateSelected(this)) {
      return;
    }

    // we do this for account codes and tags here because now they are loaded asynchronously
    // and might not be available at the time of the run. Then we use the turbo:frame-render event on
    // _augment_options_slideover partial to call loadAsyncInputs()
    let accountCodeIds = [];
    let colTagIds = [];
    let rowTagIds = [];
    let groupByAccountCodes = {};
    let groupByTags = {};

    if (this.asyncInputsLoadedValue) {
      accountCodeIds = getCheckboxValues(this.accountCodeTargets);
      colTagIds = getCheckboxValues(this.colTagTargets);
      rowTagIds = getCheckboxValues(this.rowTagTargets);
    } else {
      accountCodeIds = sessionReportOptions?.account_code_ids;

      if (sessionReportOptions?.col_tag_ids) {
        colTagIds = sessionReportOptions.col_tag_ids;
      } else if (sessionReportOptions?.tag_ids) {
        colTagIds = sessionReportOptions.tag_ids;
      }

      rowTagIds = sessionReportOptions?.row_tag_ids;

      if (!Object.prototype.hasOwnProperty.call(this.getOptionsObject(), "group_by_account_codes")) {
        groupByAccountCodes = { group_by_account_codes: sessionReportOptions?.group_by_account_codes };
      }
      if (!Object.prototype.hasOwnProperty.call(this.getOptionsObject(), "group_by_account_codes")) {
        groupByTags = { group_by_tags: sessionReportOptions?.group_by_tags };
      }
    }

    const body = {
      start_date: this.hasStartDateTarget ? this.startDateTarget.value : null,
      end_date: this.hasEndDateTarget ? this.endDateTarget.value : null,
      date_range: this.hasDateRangeTarget ? this.dateRangeTarget.value : null,
      interval: this.hasIntervalSelectTarget ? this.intervalSelectTarget.value : null,
      consolidation_format: this.hasConsolidationFormatSelectTarget ? this.consolidationFormatSelectTarget.value : null,
      comparable: this.hasCompareableTarget ? this.compareableTarget.value : null,
      compare_to_last_period: this.hasCompareToLastPeriodTarget ? this.compareToLastPeriodTarget.checked : null,
      ledger_ids: getSelectValues(this.ledgersTarget),
      account_code_ids: accountCodeIds,
      col_tag_ids: colTagIds,
      row_tag_ids: rowTagIds,
      ...groupByAccountCodes,
      ...groupByTags,
      ...this.getOptionsObject(),
    };

    if (event.type == "click") {
      sessionStorage.setItem(this.sessionStorageKey("report_options"), JSON.stringify(body));
    }

    const response = await requestReportData(body);

    processReportDataResponse(this, response, (data) => {
      this.renderGrid(data);
    });
  }

  updateBreadcrumb(event: Event) {
    const element = event.srcElement as HTMLElement;

    updateBreadcrumb(this.reportTemplateIdValue, element);
  }

  addAutoLoadParamToUrl() {
    // Skip if the url has the auto_load or report_template_id params
    if (window.location.search.includes("auto_load") || window.location.search.includes("report_template_id")) {
      return;
    }

    const url = new URLSearchParams(window.location.search);
    url.set("auto_load", "true");
    window.history.replaceState({}, document.title, window.location.origin + window.location.pathname + "?" + url);
  }

  renderGrid(data, savedOptions = null) {
    this.setColumnDefinitionsAndRowData(data);
    this.toggleMergeEntitiesToggle(savedOptions);

    this.collapseFullyUncodedRows();

    this.gridApi.setGridOption("loading", false);
  }

  loadValuesToInputs(values) {
    loadDateInputs(values, this);

    const dateValue = values.date_range;
    if (dateValue === "since_inception") {
      this.startDateTarget.disabled = true;
    } else {
      this.startDateTarget.disabled = false;
    }

    if (this.hasIntervalSelectTarget) this.intervalSelectTarget.value = values.interval;
    if (this.hasConsolidationFormatSelectTarget)
      this.consolidationFormatSelectTarget.value = values.consolidation_format;
    if (this.hasCompareableTarget) this.compareableTarget.value = values.comparable;
    if (this.hasCompareToLastPeriodTarget) this.compareToLastPeriodTarget.checked = values.compare_to_last_period;

    setSelectValues(this.ledgersTarget, values.ledger_ids.map(Number));

    // Account codes and tags are loaded asynchronously. Check loadAsyncInputs() and comments on
    // loadGridData() for more info
    setOptionsCheckboxValue(this.optionTargets, "show_zero_balance_accounts", values.show_zero_balance_accounts);
    setOptionsCheckboxValue(this.optionTargets, "show_inactive_accounts", values.show_inactive_accounts);
    setOptionsCheckboxValue(this.optionTargets, "show_consolidated_breakdown", values.show_consolidated_breakdown);
    setOptionsCheckboxValue(
      this.optionTargets,
      "show_lookthrough_consolidation",
      values.show_lookthrough_consolidation,
    );
    setOptionsCheckboxValue(this.optionTargets, "hide_account_categories", values.hide_account_categories);
    setOptionsCheckboxValue(this.optionTargets, "merge_entities", values.merge_entities);
  }

  loadAsyncInputs() {
    const sessionReportOptions = JSON.parse(sessionStorage.getItem(this.sessionStorageKey("report_options")));

    if (
      sessionReportOptions &&
      sessionReportOptions.account_code_ids &&
      (sessionReportOptions.tag_ids || sessionReportOptions.col_tag_ids)
    ) {
      setOptionsCheckboxValue(
        this.optionTargets,
        "group_by_account_codes",
        sessionReportOptions.group_by_account_codes,
      );
      setOptionsCheckboxValue(
        this.optionTargets,
        "group_by_tags",
        sessionReportOptions.group_by_tags,
      );

      setCheckboxValues(this.accountCodeTargets, sessionReportOptions.account_code_ids.map(Number));
      if (sessionReportOptions.row_tag_ids && this.hasRowTagsButtonTarget) {
        // If we have row tags, we want to select the row tags button so that they are shown
        this.rowTagsButtonTarget.click();
        setCheckboxValues(this.rowTagTargets, sessionReportOptions.row_tag_ids.map(Number));
      }

      let colTagIds = [];
      if (sessionReportOptions.col_tag_ids) {
        colTagIds = sessionReportOptions.col_tag_ids;
      } else if (sessionReportOptions.tag_ids) {
        colTagIds = sessionReportOptions.tag_ids;
      }

      setCheckboxValues(this.colTagTargets, colTagIds.map(Number));
    }

    this.asyncInputsLoadedValue = true;
    this.dispatch("report-inputs-loaded");
  }

  exportReport(event: Event) {
    event.preventDefault();

    const reportType: ReportType = this.hasReportTypeValue ? (this.reportTypeValue as ReportType) : "sumit-report";
    const reportDate = new Date();

    const fileName = `${reportType}-${reportDate.toISOString().replace(/:/g, "-")}.xlsx`;

    const { showZeroBalanceAccountsValue, showInactiveAccountsValue } = this.getTogglesValues();
    const reportOptions: ReportOption[] = [
      { label: "Showing zero balance accounts", value: showZeroBalanceAccountsValue },
      { label: "Includes inactive accounts", value: showInactiveAccountsValue },
    ];

    this.gridApi.exportDataAsExcel({
      prependContent: headerRows(
        reportType,
        reportDate,
        this.startDateTarget.value,
        this.endDateTarget.value,
        this.getSelectedLedgerNames(),
        reportOptions,
      ),
      fileName,
      author: "SumIt Software, Inc.",
      sheetName: reportType,
      rowGroupExpandState: "match",
      headerRowHeight: this.consolidatedReport() ? 100 : 30,
      shouldRowBeSkipped: function (params) {
        return !params.node.displayed;
      },
      ...defaultExportParams,
    });
  }

  collapseFullyUncodedRows(): void {
    this.gridApi.forEachNode((rowNode) => {
      const childrenAfterGroup = rowNode.childrenAfterGroup;

      if (childrenAfterGroup?.length == 1 && childrenAfterGroup[0].key == "Uncoded") {
        rowNode.setExpanded(false);
      }
    });
  }

  getReportOptions(): object {
    const options = JSON.parse(sessionStorage.getItem(this.sessionStorageKey("report_options")));
    options["ag_grid_state"] = this.gridApi.getState();

    return options;
  }

  async saveReportTemplate(e: Event) {
    e.preventDefault();

    const reportTemplateData = {
      report_type: this.saveReportTypeTarget.value,
      report_options: prepareDateRangeOptions(this.getReportOptions()),
    };

    await saveReportTemplate(reportTemplateData, this.saveReportTypeTarget.value, this.saveReportNameTarget.value);
  }

  updateSavedReportTemplate() {
    const reportTemplateData = {
      report_type: this.saveReportTypeTarget.value,
      report_options: prepareDateRangeOptions(this.getReportOptions()),
    };

    updateSavedReportTemplate(reportTemplateData, this.reportTemplateIdValue);
  }

  getOptionsObject() {
    const options = {};
    this.optionTargets.forEach((option) => {
      options[option.name] = option.checked;
    });
    return options;
  }

  setColumnDefinitionsAndRowData(data) {
    setDateAndAlertDisplay(this, data);

    const cellRenderer =
      hasOption(this, "hide_account_categories") && getOptionState(this, "hide_account_categories")
        ? "flattenedAccountCellRenderer"
        : "accountCellRenderer";

    this.gridApi.updateGridOptions({
      columnDefs: this.createColumnDefinitions(data.column_definitions, 0, data.report_summary.include_col_tags),
      autoGroupColumnDef: {
        ...this.accountGroupColumnDefinition,
        cellRendererParams: {
          suppressCount: true,
          innerRenderer: cellRenderer,
        },
      },
    });

    const originalRowData = this.gridApi.getGridOption("rowData") || [];

    // this is needed in order for the ag grid report state filters to be applied properly. It doesn't work
    // if the row_data is loaded using updateGridOptions(). The filters will show up but won't actually filter the data.
    this.gridApi.setGridOption("rowData", data.row_data);

    if (!this.gridOptions.initialState) {
      this.autoSizeColumns();
    }

    // Detect if the breadcrumbs on the rows have changed - if they have, we expand all the rows.
    // originalRowData would be empty if the report is being loaded for the first time, in which case we don't
    // want to expand all the rows
    if (originalRowData.length > 0 && shouldRefreshGrouping(originalRowData, data.row_data)) {
      this.gridApi.expandAll();
    }

    if (this.hasExportButtonTarget) {
      this.exportButtonTarget.disabled = false;
    }
    if (this.hasSaveReportButtonTarget) {
      this.saveReportButtonTarget.disabled = false;
    }
    if (this.hasUpdateReportButtonTarget && this.reportTemplateIdValue) {
      this.updateReportButtonTarget.classList.remove("hidden");
    } else {
      this.updateReportButtonTarget.classList.add("hidden");
    }
  }

  reportDateHeader() {
    const reportTypes = ["balance_sheet", "trial_balance", "consolidated", "net_worth"];

    if (reportTypes.includes(this.reportTypeValue)) {
      return `As of ${formatDateForDisplay(this.endDateTarget.value)}`;
    } else if (this.startDateTarget.value === "" && this.endDateTarget.value) {
      return "Since Inception";
    } else {
      return `For the period ${formatDateForDisplay(this.startDateTarget.value)} - ${formatDateForDisplay(
        this.endDateTarget.value,
      )}`;
    }
  }

  mergeEntitiesToggleTargetConnected() {
    this.toggleMergeEntitiesToggle();
  }

  toggleMergeEntitiesToggle(savedOptions = null) {
    if (!this.hasMergeEntitiesToggleTarget) {
      return;
    }

    const ledgerSelectValues = getSelectValues(this.ledgersTarget);
    const label = this.mergeEntitiesToggleTarget.parentElement.previousElementSibling;
    const container = label.parentElement;
    const toggle = label.nextElementSibling;

    if (ledgerSelectValues.length > 1) {
      this.mergeEntitiesToggleTarget.disabled = false;
      label.classList.add("text-sumit-primary-black");
      label.classList.remove("text-gray-300");
      container.classList.add("hover:bg-gray-200");
      toggle.classList.remove("pointer-events-none");
      if (!toggle.classList.contains("cursor-pointer")) {
        toggle.classList.add("cursor-pointer");
      }
      this.toggleCompareToLastPeriodButton(this.mergeEntitiesToggleTarget.checked, savedOptions);
    } else {
      this.mergeEntitiesToggleTarget.checked = false;
      this.mergeEntitiesToggleTarget.disabled = true;
      label.classList.remove("text-sumit-primary-black");
      label.classList.add("text-gray-300");
      container.classList.remove("hover:bg-gray-200");
      toggle.classList.remove("cursor-pointer");
      toggle.classList.add("pointer-events-none");
      this.toggleCompareToLastPeriodButton(true, savedOptions);
    }
  }

  toggleCompareToLastPeriodButton(enabled, savedOptions = null) {
    if (enabled) {
      this.compareToLastPeriodTarget.disabled = false;
      if (savedOptions?.compare_to_last_period) {
        this.compareToLastPeriodTarget.checked = true;
      }
      this.compareToLastPeriodTarget.parentElement.parentElement
        .querySelector("[data-compare-to-last-period-button-target='info']")
        .classList.add("hidden");
      this.compareToLastPeriodTarget.parentElement
        .querySelector("[data-compare-to-last-period-button-target='text']")
        .classList.remove("text-gray-400");
    } else {
      this.compareToLastPeriodTarget.disabled = true;
      this.compareToLastPeriodTarget.checked = false;
      this.compareToLastPeriodTarget.parentElement.parentElement
        .querySelector("[data-compare-to-last-period-button-target='info']")
        .classList.remove("hidden");
      this.compareToLastPeriodTarget.parentElement
        .querySelector("[data-compare-to-last-period-button-target='text']")
        .classList.add("text-gray-400");
    }
  }

  createColumnDefinitions(columnDefData: ServerProvidedColDef[], childrenLevel = 0, isColTaggedReport = false) {
    const columnDefs: ServerProvidedColDef[] = [];

    columnDefData.forEach((columnDef) => {
      // If this is a column tagged report but the column has no children defined, then we are combining entities
      if (isColTaggedReport && columnDef.children === undefined && childrenLevel === 0 && columnDef.field !== "total") {
        columnDef.headerClass = "bg-gray-200 ag-sub-header-cell";
        columnDef.headerGroupComponent = HeaderGroupRenderer;
        columnDef.headerComponentParams = {
          template: tagHeaderTemplate(columnDef, false),
        };
      }
      if (isColTaggedReport && childrenLevel > 0) {
        columnDef.headerClass = "bg-gray-200 ag-sub-header-cell";
        columnDef.headerGroupComponent = HeaderGroupRenderer;
        columnDef.headerComponentParams = {
          template: tagHeaderTemplate(columnDef, childrenLevel > 1),
        };
      }
      if (this.consolidatedReport() && columnDef?.metadata?.consolidationMetadata) {
        columnDef.headerComponentParams = {
          template: consolidatedHeaderTemplate(columnDef),
        };
      }

      if (columnDef.includeInTotal) {
        this.totalCols.add(columnDef.field);
      }

      columnDef.cellClass = getClassesForCells;
      columnDef.cellRenderer = NumberCellRenderer;

      if (columnDef.field === "percent_change_total") {
        columnDef.valueGetter = this.percentChangeTotalValueGetter;
        // for total rows the valueFormatter from the dataTypeDefinitions won't work as the rows have already been formatted.
        // Check the note at the end of this paragraph: https://www.ag-grid.com/javascript-data-grid/cell-data-types/#pre-defined-cell-data-types
        columnDef.valueFormatter = dataTypeDefinitions.percentage.valueFormatter;
      } else {
        columnDef.valueGetter = numberValueGetter;
      }

      if (columnDef.children != null) {
        columnDef.children = this.createColumnDefinitions(columnDef.children, childrenLevel + 1, isColTaggedReport);
      }

      if (columnDef.field === "total") {
        const totalCol = {
          headerName: "Total",
          colId: "total",
          aggFunc: "sum",
          resizable: false,
          minWidth: 150,
          maxWidth: 200,
          cellRenderer: NumberCellRenderer,
          cellDataType: "number",
          cellClass: getClassesForCells,
          valueGetter: this.totalValueGetter,
        };

        columnDefs.push({
          ...totalCol,
        });
      } else {
        delete columnDef.includeInTotal;
        columnDef.context = {
          metadata: columnDef.metadata,
        };
        delete columnDef.metadata;

        columnDefs.push({
          ...columnDef,
        });
      }
    });

    return columnDefs;
  }

  autoSizeColumns() {
    this.gridApi.sizeColumnsToFit();
  }

  aggPercentChangeTotal = (params: IAggFuncParams) => {
    let currentPeriodTotal = 0;
    let priorPeriodTotal = 0;

    params.values.forEach((value) => {
      if (!isNaN(value.prior_period_total)) {
        priorPeriodTotal += parseFloat(value.prior_period_total);
      }

      if (!isNaN(value.current_period_total)) {
        currentPeriodTotal += parseFloat(value.current_period_total);
      }
    });

    const changeTotal = currentPeriodTotal - priorPeriodTotal;
    const percentChangeTotal = calculatePercentChangeTotal({ priorPeriodTotal, changeTotal });

    return {
      prior_period_total: priorPeriodTotal,
      current_period_total: currentPeriodTotal,
      change_total: changeTotal,
      toString: () => percentChangeTotal,
    };
  };

  percentChangeTotalValueGetter = (params: ValueGetterParams) => {
    // no need to handle group levels - calculated in the "aggPercentChangeTotal" aggFunc
    if (!params.node.group) {
      const percentChangeTotal = calculatePercentChangeTotal({
        priorPeriodTotal: params.data.prior_period_total,
        changeTotal: params.data.change_total,
      });

      return {
        prior_period_total: params.data.prior_period_total,
        current_period_total: params.data.current_period_total,
        change_total: parseFloat(params.data.change_total),
        toString: () => percentChangeTotal,
      };
    }
  };

  totalValueGetter = (params: ValueGetterParams) => {
    const node = params.node as unknown as RowNode;

    // Don't want to display anything for group headers, the totals are handled in the footer.
    if (isGroupHeader(node)) {
      return null;
    } else {
      const cols = Array.from(this.totalCols);

      return aggColumnValues(params, cols);
    }
  };

  accountComparator(valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode, _isInverted: boolean): number {
    const accountOrder = [
      "Assets",
      "Liabilities And Equity",
      "Liabilities",
      "Equities",
      "Revenues",
      "Expenses",
      "Net Income",
      "Initial Balance",
      "Sources of Cash",
      "Uses of Cash",
      "Ending Balance",
      "Total Net Worth",
    ];
    const nodeAAccountNumberAndName = nodeA?.data?.accountNumberAndName;
    const nodeBAccountNumberAndName = nodeB?.data?.accountNumberAndName;

    // If this is a top-level account, organize by account order
    if (
      typeof valueA === "string" &&
      typeof valueB === "string" &&
      accountOrder.indexOf(valueA.split(" - ").at(-1)) != -1 &&
      accountOrder.indexOf(valueB.split(" - ").at(-1)) != -1
    ) {
      return accountOrder.indexOf(valueB.split(" - ").at(-1)) - accountOrder.indexOf(valueA.split(" - ").at(-1));
    } else if (
      nodeAAccountNumberAndName &&
      nodeBAccountNumberAndName &&
      accountOrder.indexOf(nodeAAccountNumberAndName.split(" - ").at(-1)) != -1 &&
      accountOrder.indexOf(nodeBAccountNumberAndName.split(" - ").at(-1)) != -1
    ) {
      return (
        accountOrder.indexOf(nodeBAccountNumberAndName.split(" - ").at(-1)) -
        accountOrder.indexOf(nodeAAccountNumberAndName.split(" - ").at(-1))
      );
    } else if (nodeAAccountNumberAndName && nodeBAccountNumberAndName) {
      // If this is a child account, order by account number and name
      return nodeBAccountNumberAndName.toString().localeCompare(nodeAAccountNumberAndName.toString());
    } else if (typeof valueA === "string" && typeof valueB === "string") {
      // Otherwise order alphabetically
      return valueB.toString().localeCompare(valueA.toString());
    } else {
      return 0;
    }
  }

  validateEntitySetIfConsolidatedReport() {
    if (this.consolidatedReport()) {
      return validateEntitySet(this);
    } else {
      return true;
    }
  }

  getTogglesValues() {
    const showZeroBalanceAccountsCheckbox = document.querySelector("#show_zero_balance_accounts") as HTMLInputElement;
    const showInactiveAccountsCheckbox = document.querySelector("#show_inactive_accounts") as HTMLInputElement;

    const showZeroBalanceAccountsValue = showZeroBalanceAccountsCheckbox?.checked;
    const showInactiveAccountsValue = showInactiveAccountsCheckbox?.checked;

    return { showZeroBalanceAccountsValue, showInactiveAccountsValue };
  }

  get accountGroupColumnDefinition() {
    return {
      headerName: "Account",
      minWidth: 300,
      flex: 1,
      pinned: "left" as ColumnPinnedType,
      cellClass: getClassesForAccounts,
      cellRendererSelector: (_params) => {
        return { component: "agGroupCellRenderer" };
      },
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: "accountCellRenderer",
      },
      sort: "desc" as SortDirection,
      comparator: this.accountComparator,
    } as ColDef;
  }

  getSelectedLedgerNames() {
    return getSelectValues(this.ledgersTarget).map(
      (id) => (this.ledgersTarget.querySelector(`option[value="${id}"]`) as HTMLOptionElement).innerText,
    );
  }

  consolidatedReport() {
    return ["consolidated", "net_worth"].includes(this.reportTypeValue);
  }

  sessionStorageKey(type: string) {
    return `reporting_${this.reportTypeValue}_report:${type}`;
  }
}
